<template lang="pug">
  .price-table-tab
    AppOverlayLoader(:state="priceTablesLoading")
    .price-table-item(v-if="isPriceTableFormShown")
      Form(
        :price-table-id="selectedPriceTableId"
        :time-slots="timeSlots"
        :car-classes="carClasses"
        @back="hidePriceTableForm"
      )
    .price-table-content(v-else)
      ActionBar(
        @add-new="showPriceTableForm"
        :with-download="false"
      )
      Table(
        :items="priceTables"
        :sorting-data="sortingData"
        @select-row="selectPriceTable"
        @remove-row="removePriceTable"
        @sorting="changeSorting"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  const TIME_SLOT_SERIALIZER_VIEW = "extended"

  // misc
  import { SERIALIZER_VIEW_SHORT } from "@/config/constants"
  import { appDebounce } from "@/helpers/common"

  // store modules
  import priceTableModule from "@/config/store/company_system/rate_plan_settings/price_table"
  import timeSlotsModule from "@/config/store/company_system/basic_settings/time_slots"
  import carClassesModule from "@/config/store/company_system/car_class_settings/car_classes"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const priceTableMixin = withStoreModule(priceTableModule, {
    name: "priceTables",
    readers: {
      priceTables: "items",
      priceTablesLoading: "loading",
      sortingData: "sorting",
      pagination: "pagination"
    },
    actions: {
      fetchPriceTables: "FETCH_ITEMS",
      deletePriceTable: "DELETE_ITEM"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setFilters: "SET_FILTERS",
      setSorting: "SET_SORTING"
    }
  })

  const timeSlotsMixin = withStoreModule(timeSlotsModule, {
    name: "timeSlots",
    readers: { timeSlots: "items" },
    actions: { fetchTimeSlots: "FETCH_ITEMS" }
  })

  const carClassSettingsMixin = withStoreModule(carClassesModule, {
    name: "carClassSettings",
    readers: { carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  export default {
    components: {
      ActionBar: () => import("../../ActionBar"),
      Table: () => import("./Table"),
      Form: () => import("./Form"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [priceTableMixin, timeSlotsMixin, carClassSettingsMixin, withConfirmation],

    data() {
      return {
        isPriceTableFormShown: false,
        selectedPriceTableId: null
      }
    },

    created() {
      this.debouncedFetchPriceTables = appDebounce(() => this.fetchPriceTables())
    },

    mounted() {
      this.fetchPriceTables()
      this.fetchTimeSlots({
        pagination: { _disabled: true },
        serializer_view: TIME_SLOT_SERIALIZER_VIEW
      })
      this.fetchCarClasses({
        pagination: { _disabled: true },
        serializer_view: SERIALIZER_VIEW_SHORT
      })
    },

    methods: {
      showPriceTableForm() {
        this.isPriceTableFormShown = true
      },

      hidePriceTableForm() {
        this.isPriceTableFormShown = false
        this.selectedPriceTableId = null
        this.fetchPriceTables()
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.debouncedFetchPriceTables()
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.debouncedFetchPriceTables()
      },

      selectPriceTable({ id }) {
        this.selectedPriceTableId = id
        this.isPriceTableFormShown = true
      },

      removePriceTable({ id }) {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.delete_price_table_confirmation"),
          resolve: {
            handler: () => {
              this.deletePriceTable(id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped></style>
